import React from 'react';
import { AppContext } from '../../Context';
import './FormFields.css'; // Importando o arquivo CSS

const FormFields = () => {

  const { formData, setFormData } = React.useContext(AppContext)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Dados do formulário:', formData);
    // Aqui você pode enviar os dados para uma API ou realizar outra ação
  };

  return (
    <form onSubmit={handleSubmit} className="formulario">
      <div className="form-group">
        <input
          id="responsavel"
          name="responsavel"
          placeholder='Nome do responsável...'
          type="text"
          value={formData.responsavel}
          onChange={handleChange}
          className="input"
          required
        />
      </div>

      <div className="form-group">
        <input
          id="telefone"
          name="telefone"
          placeholder='Telefone...'
          type="text"
          value={formData.telefone}
          onChange={handleChange}
          className="input"
          required
        />
      </div>

      <div className="form-group">
        <input
          id="email"
          name="email"
          placeholder='email...'
          type="text"
          value={formData.email}
          onChange={handleChange}
          className="input"
          required
        />
      </div>

      <div className="form-group">
        <input
          id="aluno"
          name="aluno"
          placeholder='Nome do aluno...'
          type="text"
          value={formData.aluno}
          onChange={handleChange}
          className="input"
          required
        />
      </div>

      <div className="form-group">
        <select
          id="serie"
          name="serie"
          placeholder="Série desejada..."
          value={formData.serie}
          onChange={handleChange}
          className="input"
          required
        >
          <option value="" disabled>Série desejada...</option>
          <option value="6º ano - F">6º ano - Fundamental</option>
          <option value="7º ano - F">7º ano - Fundamental</option>
          <option value="8º ano - F">8º ano - Fundamental</option>
          <option value="9º ano - F">9º ano - Fundamental</option>
          <option value="1º ano - M">1º ano - Médio</option>
          <option value="2º ano - M">2º ano - Médio</option>
          <option value="3º ano - M">3º ano - Médio</option>
        </select>
      </div>
    </form>

  );
};

export default FormFields;
