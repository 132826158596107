import React from 'react';

export default function VideoPlayer({ url, show = false }) {

    return (
      <div className="VideoPlayer">
          <video className="Video" autoPlay muted loop width="100%" height="100%">
            <source src={url} type="video/mp4"/>
          </video>
      </div>
    )
}