/* eslint-disable no-throw-literal */
import React from 'react';
import axios from 'axios';
import sign from 'jwt-encode';
import moment from 'moment';

const AppContext = React.createContext({});

function AppProvider({ children }) {
  const [videoPlay, _setVideoPlay] = React.useState(false);
  const [formExpandOne, _setFormExpandOne] = React.useState(false);
  const [formExpandTwo, _setFormExpandTwo] = React.useState(false);
  const [formSending, setFormSending] = React.useState(false);
  const [formData, setFormData] = React.useState({
    responsavel: '',
    telefone: '',
    email: '',
    aluno: '',
    serie: '' // Valor inicial para o select
  });

  const setFormExpand = React.useCallback((index, value) => {
    if(index === 1) _setFormExpandOne(value);
    if(index === 2) _setFormExpandTwo(value);
  },[_setFormExpandOne, _setFormExpandTwo]);

  const expandAll = React.useCallback((value = true) => {
    _setFormExpandOne(value);
    _setFormExpandTwo(value);
  },[_setFormExpandOne, _setFormExpandTwo]);
  
  const setVideoPlay = React.useCallback((value) => {
    _setVideoPlay(value)
  }, [_setVideoPlay])

  const sendForm = React.useCallback(async () => {
    if(!formData.telefone && !formData.email) return { message: "Por favor, digite seu telefone ou e-mail." };

    const form = new FormData();
    form.append('de_aluno', formData.aluno);
    form.append('de_responsavel', formData.responsavel);
    form.append('de_telefone', formData.telefone);
    form.append('de_email', formData.email);
    form.append('de_serie_desejada', formData.serie);
    form.append('acao', 'salva');

    try {
      const chave = "276jhgKJG152aNBO"; 
      const expire_in = moment().add(1, 'hour').unix();
      
      const payload = {
        id: 'EXTERNO',
        name: 'EXTERNO',
        expires_in: expire_in
      };
      const token = sign(payload, chave)
      setFormSending(true);
      await axios.post('https://intranet.unifafire.edu.br/api/sj_interessado', form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setFormSending(false);
    } catch (e) {
      setFormSending(false);
      console.log(e);
      return { message: 'Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.' }
    }
    setFormSending(false);
    return { message: 'Mensagem enviada com sucesso! Em breve entraremos em contato.'}
  }, [formData]);

  return (
    <AppContext.Provider value={{
      formData,
      setFormData,
      formExpandOne,
      formExpandTwo,
      setFormExpand,
      expandAll,
      videoPlay,
      setVideoPlay,
      sendForm,
      formSending
    }}
    >{children}</AppContext.Provider>
  )
}

export {
  AppContext,
  AppProvider
}