import React from 'react';
import './Title.css';

function Title ({ children }) {
  return (
    <div className="Title">
      {children}
    </div>
  )
}

export default Title;