import React from 'react';
import { AppContext } from '../../Context';
import Title from "../Title/Title"
import FormFields from "../FormFields/FormFields"
import Logo from '../../assets/whatsapp.png';

import './Formulario.css';

export default function Formulario({ formStyle, index, expandForm }) {

  const { setFormExpand, formData, sendForm } = React.useContext(AppContext);

  React.useEffect(() => {
  },[expandForm])

  async function handleClickReservar() {
    if(!expandForm) {
      setFormExpand(index, true)
    } else {
      const response = await sendForm();
      alert(response.message);
    }
  }

  function handleClickWhatsapp() {
    const respName = formData && formData.responsavel ? `meu nome é ${formData.responsavel},` : '';
    const course = formData && formData.serie ? ` do${formData.serie}` : '';
    const whatsMessage = `Olá, ${respName} gostaria de informações sobre matrículas${course}.`;
    window.open(`https://wa.me/?text=${whatsMessage.replaceAll(" ", "%20")}`, "_blank")
  }

  return (
    <div className={`FormContainer ${formStyle} ${expandForm ? 'expand' : 'spoiler'}`}>
      { !expandForm ? null : (<Title>Quero mais informações sobre matrículas</Title>) }
      { !expandForm ? null : (<FormFields/>) }
      <div className="Buttons">
        <button type="submit" className="submit-btn" onClick={() => handleClickReservar()}>Reservar Vaga</button>
        <img src={Logo} alt="whatsapp" className="whats-btn" onClick={handleClickWhatsapp}/>
      </div>
    </div>
  )
}