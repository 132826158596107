import React from 'react';
import Title from "../Title/Title"
import FormFields from "../FormFields/FormFields"
import { AppContext } from '../../Context';
import Logo from '../../assets/whatsapp.png';

import './FormularioEstatico.css';

export default function Formulario({ formStyle }) {

  const { formData, sendForm } = React.useContext(AppContext)
  async function handleClickReservar() {
    const response = await sendForm();
    alert(response.message);
  }

  function handleClickWhatsapp() {
    const respName = formData && formData.responsavel ? `meu nome é ${formData.responsavel},` : '';
    const course = formData && formData.serie ? ` do${formData.serie}` : '';
    const whatsMessage = `Olá, ${respName} gostaria de informações sobre matrículas${course}.`;
    window.open(`https://wa.me/15551234567?text=${whatsMessage.replaceAll(" ", "%20")}`, "_blank")
  }

  return (
    <div className={`FormContainerStatic ${formStyle}`}>
      <Title>Quero mais informações sobre matrículas</Title>
      <FormFields/>
      <div className="Buttons">
        <button type="submit" className="submit-btn" onClick={() => handleClickReservar()}>Reservar Vaga</button>
        <img src={Logo} alt="whatsapp" className="whats-btn" onClick={handleClickWhatsapp}/>
      </div>
    </div>
  )
}