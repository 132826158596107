import React from 'react';
import { AppContext } from './Context';
import './App.css';
import FormularioEstatico from './components/FormularioEstatico/FormularioEstatico';
import FormularioEstaticoVideo from './components/FormularioEstaticoVideo/FormularioEstaticoVideo';
import Formulario from './components/Formulario/Formulario';
import VideoPlayer from './components/VideoPlayer/VideoPlayer';
// import BackImg from './assets/back.jpg';


function App() {
  const {formExpandOne, formExpandTwo, expandAll } = React.useContext(AppContext);

  window.addEventListener('scroll', handleScroll);

  function handleScroll(e) {
    // if(e.target.scrollingElement.scrollTop >= 2550 && !videoPlay) {
    //   console.log("play no video")
    //   setVideoPlay(true);
    // } else {
    //   if(videoPlay){
    //     setVideoPlay(false);
    //     console.log("pause no video")
    //   }
    // }
  }

  function handleClick(e) {
    if(e.target.className === "App"){
      if((formExpandOne || formExpandTwo)) expandAll(false)
    } 
  }

  return (
      <div className="App" onClick={e => handleClick(e)} onScroll={handleScroll}>
        <FormularioEstatico formStyle="formContainerOne"/>
        <Formulario formStyle="formContainerTwo" index={1} expandForm={formExpandOne}/>
        <Formulario formStyle="formContainerThree" index={2} expandForm={formExpandTwo}/>
        <FormularioEstatico formStyle="formContainerFour"/>
        <VideoPlayer url="videos/video.mp4"/>
        <FormularioEstaticoVideo formStyle="formContainerFive"/>
      </div>
  );
}

export default App;
